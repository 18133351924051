
import Vue from 'vue';

export default {
    name: 'layouts-main',
    middleware: 'stats',
    components: {},
    data() {
        return {}
    },
    created() {},
    mounted() {
        this.setProto()
    },
    methods: {
        // 购物车更新
        setProto() {
            const updateBagQuantity = () => {
                this.$updateBodyBagQuantity && this.$updateBodyBagQuantity()
                this.$updateHeadBagQuantity && this.$updateHeadBagQuantity()
                this.$updateAsideBagQuantity && this.$updateAsideBagQuantity()
            };
            Vue.prototype.$updateBagQuantity = updateBagQuantity
        },
    }
}
