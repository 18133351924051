
import Vue from 'vue';
import TripartiteChangeStorage from '/pages/tripartite/changeStorage/index'
import TripartitePopAd from '/pages/tripartite/popAd/index'
import TripartiteEchatCustomer from '/pages/tripartite/echat/index'
import TripartiteCommon from '/pages/tripartite/common/index'
import TripartiteBuried from '/pages/tripartite/buried/index'
import TripartiteSeoDefault from '/pages/tripartite/seo/index'
import TripartiteAside from '/pages/tripartite/aside/index'
import TripartiteRegisterSuccess from '/pages/tripartite/registerSuccess/index'
import TripartiteBusiness from '/pages/tripartite/business/index'

export default {
    name: 'layouts-main-footer',
    middleware: 'stats',
    components: {
        TripartiteChangeStorage,
        TripartitePopAd,
        TripartiteEchatCustomer,
        TripartiteCommon,
        TripartiteBuried,
        TripartiteSeoDefault,
        TripartiteAside,
        TripartiteRegisterSuccess,
        TripartiteBusiness
    },
    asyncData() {},
    data() {
        return {
            hasDelay: false
        }
    },
    created() {},
    mounted() {
        this.initialSet()
        this.setProto()
    },
    methods: {
        // 购物车更新
        setProto() {
            const updateBagQuantity = () => {
                this.$updateBodyBagQuantity && this.$updateBodyBagQuantity()
                this.$updateHeadBagQuantity && this.$updateHeadBagQuantity()
                this.$updateAsideBagQuantity && this.$updateAsideBagQuantity()
            };
            Vue.prototype.$updateBagQuantity = updateBagQuantity
        },
        initialSet() {
            const keyArr = ['PaySuccess', 'PaySuccess-Old'];
            const times = keyArr.includes(this.$route.name) ? 0 : 3000;
            let clear = setTimeout(() => {
                this.hasDelay = true
                clearTimeout(clear)
            }, times)
        }
    }
}
