import { render, staticRenderFns } from "./main-footer.vue?vue&type=template&id=25026ecd&scoped=true&"
import script from "./main-footer.vue?vue&type=script&lang=js&"
export * from "./main-footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25026ecd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/home/php1/m-nuxt/components/Footer.vue').default})
